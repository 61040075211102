<template>
  <div>
    <div class="flex mb-4 justify-between">
      <h1 class="font-medium text-3xl">{{ $t("instructions") }}</h1>
      <button
        v-if="role != 99"
        @click="open = true"
        class="bg-green-500 px-3 rounded text-gray-200"
      >
        <i class="el-icon-plus"></i> {{ $t("add") }}
      </button>
    </div>
    <div class="flex">
      <div class="flex-1 overflow-hidden">
        <el-table
          :data="Instructions"
          border
          v-loading="loading"
          style="width: 100%"
          class="rounded-lg shadow-md"
        >
          <el-table-column prop="id" align="center" label="ID" width="60">
          </el-table-column>
          <el-table-column prop="name" :label="$t('name')" min-width="180">
          </el-table-column>
          <el-table-column :label="$t('file')" min-width="220">
            <template slot-scope="scope">
              <a :href="scope.row.file" target="_blank" :alt="scope.row.file">
                {{ decodeURI(scope.row.file).split("/").pop() }}
              </a>
            </template>
          </el-table-column>
          <el-table-column
            v-if="role != 99"
            :label="$t('actions')"
            fixed="right"
            :width="actionsWidth"
          >
            <template slot-scope="scope">
              <el-button size="mini" @click="openEdit(scope.row.id)">
                <i class="el-icon-edit"></i>
                <span class="hidden lg:inline-block">{{ $t("edit") }}</span>
              </el-button>
              <el-popconfirm
                :confirm-button-text="$t('yes')"
                :cancel-button-text="$t('no_thanks')"
                icon="el-icon-info"
                icon-color="red"
                :title="$t('you_want_to_delete')"
                class="ml-2"
                @confirm="InstructionDelete(scope.row.id)"
              >
                <el-button slot="reference" size="mini" type="danger">
                  <i class="el-icon-delete"></i>
                  <span class="hidden lg:inline-block">{{ $t("delete") }}</span>
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <div class="mt-3 flex justify-end">
          <el-pagination
            background
            layout="prev, pager, next"
            hide-on-single-page
            @prev-click="Prev"
            @next-click="Next"
            @current-change="handleCurrentChange"
            :total="totalCount"
          >
          </el-pagination>
        </div>
      </div>
      <el-drawer
        :visible.sync="open"
        :with-header="false"
        size="400px"
        @close="CloseDrawer"
        v-if="role != 99"
      >
        <div
          class="h-20 bg-111E52 flex items-center justify-between px-3 text-white text-xl"
        >
          <p>SYSTEM</p>
          <button @click="open = false" class="px-2">
            <i class="el-icon-close"></i>
          </button>
        </div>
        <div class="p-4">
          <h1 class="mb-4">{{ $t("add_instruction") }}</h1>
          <el-form
            ref="instruction"
            label-position="top"
            :model="instruction"
            @submit.prevent.native
          >
            <el-form-item
              prop="name"
              :label="$t('name')"
              :rules="{
                required: true,
                message: $t('please_input'),
                trigger: 'blur',
              }"
            >
              <el-input
                :placeholder="$t('input')"
                v-model="instruction.name"
                clearable
              >
              </el-input>
            </el-form-item>

            <input type="file" class="hidden" id="file" @change="addFile" />
            <div class="mb-2">
              {{ fileUrl }}
            </div>
            <label
              for="file"
              class="cursor-pointer border border-gray-500 bg-gray-50 shadow-sm px-3 py-1 rounded text-gray-500"
              >{{ $t("choose_file") }}</label
            >
          </el-form>

          <div class="text-right">
            <el-button
              v-if="!edit"
              @click="addInstruction"
              type="primary"
              :loading="loading"
            >
              {{ $t("add") }}
            </el-button>
            <el-button
              v-else
              @click="editInstruction"
              type="primary"
              :loading="loading"
            >
              {{ $t("save") }}
            </el-button>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "carriagreType",
  data() {
    return {
      actionsWidth: 270,
      open: false,
      edit: false,
      instruction: {
        file: "",
        name: "",
      },
      fileUrl: "",
      id: "",
      Instructions: [],
      totalCount: 0,
      count: 1,
      loading: false,
      role: localStorage.role,
    };
  },
  created() {
    this.getInstructions();
    window.innerWidth < 992 ? (this.actionsWidth = 120) : "";
  },
  methods: {
    async getInstructions() {
      this.loading = true;
      await axios
        .get(`instructions/${this.count === 1 ? "" : "?page=" + this.count}`)
        .then((response) => {
          this.totalCount = response.data.count;
          this.Instructions = response.data.results;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    Prev() {
      this.count--;
      this.getInstructions();
    },
    handleCurrentChange(val) {
      this.count = val;
      this.getInstructions();
    },
    Next() {
      this.count++;
      this.getInstructions();
    },
    addFile(e) {
      this.instruction.file = e.target.files[0];
      this.fileUrl = this.instruction.file.name;
    },
    clearFile() {
      this.instruction.file = "";
      this.fileUrl = "";
      document.getElementById("file").value = "";
    },
    openEdit(id) {
      this.open = true;
      this.edit = true;
      this.id = id;
      let data = this.Instructions.find((data) => data.id === id);
      this.instruction.name = data.name;
      this.fileUrl = data.file.split("/").pop();
    },
    CloseDrawer() {
      this.edit = false;
      this.instruction.name = "";
      this.clearFile();
      this.$refs.instruction.clearValidate();
    },
    editInstruction() {
      this.$refs.instruction.validate((valid) => {
        if (valid) {
          const formData = new FormData();
          this.instruction.file
            ? formData.append("file", this.instruction.file)
            : "";
          this.instruction.name
            ? formData.append("name", this.instruction.name)
            : "";
          this.loading = true;
          axios
            .patch(`instructions/${this.id}/`, formData)
            .then(() => {
              this.loading = false;
              this.open = false;
              this.getInstructions();
              this.$notify({
                title: this.$t("success"),
                message: this.$t("data_changed"),
                type: "success",
              });
            })
            .catch(() => {
              this.loading = false;
              this.$notify.error({
                title: this.$t("error"),
                message: this.$t("data_unchanged"),
              });
            });
        }
      });
    },
    addInstruction() {
      this.$refs.instruction.validate((valid) => {
        if (valid) {
          if (this.instruction.file) {
            this.loading = true;
            const formData = new FormData();
            formData.append("file", this.instruction.file);
            formData.append("name", this.instruction.name);
            axios
              .post("instructions/", formData)
              .then(() => {
                this.loading = false;
                this.open = false;
                this.getInstructions();
                this.$notify({
                  title: this.$t("success"),
                  message: this.$t("data_added"),
                  type: "success",
                });
              })
              .catch(() => {
                this.loading = false;
                this.$notify.error({
                  title: this.$t("error"),
                  message: this.$t("no_data_added"),
                });
              });
          } else {
            this.$notify.error({
              title: this.$t("error"),
              message: this.$t("no_file"),
            });
          }
        }
      });
    },
    async InstructionDelete(id) {
      this.loading = true;
      await axios
        .delete(`instructions/${id}/`)
        .then(() => {
          this.totalCount % 10 === 1 && this.totalCount / 10 >= 1
            ? this.count--
            : "";
          this.loading = false;
          this.getInstructions();
          this.$notify({
            title: this.$t("success"),
            message: this.$t("data_deleted"),
            type: "success",
          });
        })
        .catch(() => {
          this.loading = false;
          this.$notify.error({
            title: this.$t("error"),
            message: this.$t("data_not_deleted"),
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
